<template>
  <Bar
    :options="chartOptions"
    :data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: 'BarChart',
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    backgroundColor: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels, //['10', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0'],
        datasets: [
          {
            // label: 'Data One',
            //backgroundColor: '#f87979',
            backgroundColor: this.backgroundColor,
            data: this.data,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: { legend: { display: false } },
        barThickness: 4,
        borderRadius: 5,
      },
    };
  },
  watch: {
    backgroundColor(val) {
      if (val) {
        this.chartData = {
          labels: this.labels,
          datasets: [
            {
              backgroundColor: val,
              data: this.data,
            },
          ],
        };
      }
    },
    data(val) {
      if (val) {
        this.chartData = {
          labels: this.labels,
          datasets: [
            {
              backgroundColor: this.backgroundColor, //#41B883', '#E46651', '#00D8FF
              data: val,
            },
          ],
        };
      }
    },
    labels(val) {
      if (val) {
        this.chartData = {
          labels: val,
          datasets: [
            {
              backgroundColor: this.backgroundColor, //#41B883', '#E46651', '#00D8FF
              data: this.data,
            },
          ],
        };
      }
    },
  },
};
</script>
