<template>
  <div style="width: 100%">
    <v-container>
      <v-row>
        <!-- loopa genom stats array och gör charts för varje fråga -->
        <div style="width: 100%" v-if="noData">
          <span style="margin-left: 50%">Ingen data</span>
        </div>
        <template v-for="(item, index) in statsArray">
          <v-col :key="index" cols="12" xl="6" lg="6" md="6" sm="6">
            <v-card style="margin-left: 0px" flat class="cardStyle">
              <v-row justify="center">
                <v-label>{{ item.header }}</v-label>
              </v-row>

              <v-row justify="center">
                <div>
                  <BarChartH
                    :height="200"
                    :data="item.data"
                    :backgroundColor="statsBarColor"
                    :labels="item.labels"
                    :width="320"
                  />
                </div>
              </v-row>

              <v-row justify="center">
                <v-label
                  ><span class="gradeLabel">Antal svar: {{ item.sum }} </span>
                </v-label>
                <v-label v-if="item.average != ''"
                  ><span class="gradeLabel"
                    >Medelbetyg: {{ item.average }}</span
                  ></v-label
                >
              </v-row>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import mixin from '@/mixin.js';
import BarChartH from '@/components/charts/BarChartHorizontal.vue';

import get from 'lodash/get';
export default {
  name: 'NPS-results',
  props: { maxScale: String, statsType: String },
  mixins: [mixin],
  data() {
    return {
      statsArray: [],
      gradeQuestions: [],
      // statsBarColor: ['#ff0000'],
      gradeLabel: [5, 4, 3, 2, 1],
      noData: false,
    };
  },
  computed: {
    chartData() {
      var data = get(this.$store.state.infopingstore, 'statsData.items', null);

      return data;
    },
    statsBarColor() {
      if (this.org == 'SMH') return ['#4d207a'];
      else if (this.org == 'EO') return ['#F8921F'];
      else return ['#bebebe'];
    },
  },
  watch: {
    chartData() {
      this.calculateGrades();
    },
  },
  components: { BarChartH },
  beforeMount() {},
  mounted() {
    //  this.calculateGrades();
  },
  methods: {
    calculateGrades() {
      //töm statistiken från förra filtreringen
      this.statsArray = [];
      this.gradeQuestions = [];

      //hitta alla frågor
      if (this.chartData) {
        this.noData = false;
        if (this.org == 'SMH') this.smhGrades();
        if (this.org == 'EO') {
          this.eoGrades();
          this.eoReasonToHire();
          //    this.eoBidding();
        }
      } else {
        this.noData = true;
      }
    },
    smhGrades() {
      this.chartData.forEach((element) => {
        if (!this.gradeQuestions.some((item) => item === element.Question))
          this.gradeQuestions.push(element.Question);
      });

      this.gradeQuestions.forEach((header) => {
        //filtrera svaren, hitta betygen för varje fråga
        const filteredAnswers = this.chartData
          .filter((a) => a.Question.includes(header))
          .map((c) => parseInt(c.Rating));

        //loopa genom betygen, filtrera svaren efter betyg 5, räkna (length), sen 4 osv
        var gradeData = [];
        var totalAnsw = 0;
        var average = 0;

        for (var i = 5; i >= 1; i--)
          gradeData.push(filteredAnswers.filter((a) => a === i).length);

        //beräkna medelvärdet summera med avancerad magi
        const sum = filteredAnswers.reduce((a, b) => a + b, 0);
        const avg = sum / filteredAnswers.length || 0;
        totalAnsw = filteredAnswers.length;
        average = avg.toFixed(1);

        //pusha till statsArray

        this.statsArray.push({
          data: gradeData,
          labels: this.gradeLabel,
          header: this.translatedHeader(header).toUpperCase(),
          sum: totalAnsw,
          average: average,
        });
      });
    },

    eoGrades() {
      this.chartData.forEach((element) => {
        if (element.Question.includes('Mäklarens betyg'))
          if (!this.gradeQuestions.some((item) => item === element.Question))
            this.gradeQuestions.push(element.Question);
      });

      this.gradeQuestions.forEach((header) => {
        //filtrera svaren, hitta betygen för varje fråga
        const filteredAnswers = this.chartData
          .filter((a) => a.Question.includes(header))
          .map((c) => parseInt(c.Rating));

        //loopa genom betygen, filtrera svaren efter betyg 5, räkna (length), sen 4 osv
        var gradeData = [];
        var totalAnsw = 0;
        var average = 0;

        for (var i = 5; i >= 1; i--)
          gradeData.push(filteredAnswers.filter((a) => a === i).length);

        //beräkna medelvärdet summera med avancerad magi
        const sum = filteredAnswers.reduce((a, b) => a + b, 0);
        const avg = sum / filteredAnswers.length || 0;
        totalAnsw = filteredAnswers.length;
        average = avg.toFixed(1);

        //pusha till statsArray

        this.statsArray.push({
          data: gradeData,
          labels: this.gradeLabel,
          header: this.translatedHeader(header).toUpperCase(),
          sum: totalAnsw,
          average: average,
        });
      });
    },

    // eoReasonToHire() {
    //frågan verkar inte finnas med än
    //   var reason = [];
    //   //  var theAnswer = [];
    //   var totalAnsw = 0;
    //   this.chartData.forEach((element) => {
    //     if (element.Question.includes('skälet')) console.log('elemen', element);
    //     if (element.Question.includes('skälet'))
    //       if (!reason.some((item) => item === element.Rating))
    //         reason.push(element.Rating);
    //   });
    //   console.log('reason', reason);
    //   if (reason && reason.length) totalAnsw = reason.length;

    //   var count = [];
    //   var header = [];

    //   reason.forEach((val) => {
    //     header.push(val);
    //     count.push(this.chartData.filter((obj) => obj.Question === val).length);
    //   });
    //   if (totalAnsw && totalAnsw > 0) {
    //     this.statsArray.push({
    //       data: count,
    //       labels: header,
    //       header: 'FRÄMSTA SKÄLET TILL ATT DU ANLITADE OSS',
    //       sum: totalAnsw,
    //       average: '',
    //     });
    //   }
    // },
    translatedHeader(header) {
      if (header.includes('mäklarens kompetens')) return 'Mäklarens kompetens';
      else if (header.includes('mäklarens engagemang'))
        return 'Mäklarens engagemang';
      else if (header.includes('köpekontraktshandlingarna inför'))
        return 'Förklaring av köpekontraktshandlingar';
      else if (header.includes('presenterade mäklartjänsten'))
        return 'Presentation av mäklartjänsten';
      else if (header.includes('bostaden presenterades av'))
        return 'Presentation av bostaden';
      else if (header.includes('upplevde du första kontakten med'))
        return 'Första kontakten';
      else if (
        header.includes('upplevde du informationen från') &&
        header.includes('inför och efter visningen')
      )
        return 'Information före och efter visningen';
      else return header;
    },
  },
};
</script>

<style scoped>
.gradeLabel {
  font-size: 14px !important;
  padding-left: 5px;
}
</style>
