import {
  USER_SESSION,
  LANG_FILTER,
  SHOW_ALERT,
  ACCESS,
  USER_TYPE,
  BROKERS,
  LOCAL_USER,
  TEMP_USER,
  SOURCE_ID,
  SOURCE_NAME,
  SOURCE_NUMBER,
  STATS_DATA,
  STATS_STRUCTURE,
  STATS_BROKERS,
  STATS_FOLLOW_UP,
  PDF_DATA,

  //SERVICE_ORDER,
} from './types.js';

import rest2Api from '@/api/rest2Api.js';
//import azureApi from '@/api/azureApi.js';
import get from 'lodash/get';

export default {
  async clear_all({ commit }) {
    commit(USER_SESSION, null);
    commit(ACCESS, null);
    commit(TEMP_USER, null);
    //commit(SERVICE_ORDER, null);
  },

  async clear_structure({ commit }) {
    commit(STATS_STRUCTURE, null);
  },

  async addUserSession({ commit }, session) {
    commit(USER_SESSION, session);
  },

  async addLocalUser({ commit }) {
    commit(LOCAL_USER, true);
  },

  // brings up a simple alert message box
  async showAlert({ commit }, alertData) {
    commit(SHOW_ALERT, alertData);
  },

  async fetch_lang_filter({ commit }, obj) {
    var ans = await rest2Api.lang_filter(obj);

    commit(LANG_FILTER, ans);
    return ans;
  },

  async check_access({ commit }, session) {
    const obj = new Object();

    obj.session = session;
    var response = await rest2Api.postBackEndNoFunc('access', obj);
    if (response.success == '1') {
      commit(USER_TYPE, response.user_type);
      if (response.access && response.access.length > 0)
        commit(ACCESS, response.access);
      else commit(ACCESS, []);
    } else {
      commit(ACCESS, []);
      commit(USER_TYPE, '');
    }
  },

  async set_temp_user({ commit }, user) {
    commit(TEMP_USER, user);
  },

  async fetch_brokers({ commit, state }, obj) {
    if (obj && obj.session == null) {
      obj.session = get(state, 'session', []);
    }

    if (obj && obj.no_items) obj.no_items = null;

    var response = await rest2Api.postBackEndNoFunc('access', obj);

    if (response.access) commit(BROKERS, response.access[0].orgs);
  },

  async clear_brokers({ commit }) {
    commit(BROKERS, []);
  },

  async fetch_source_id({ commit }, obj) {
    if (obj) {
      commit(SOURCE_ID, obj);
    }
  },

  async fetch_source_name({ commit }, obj) {
    if (obj) {
      commit(SOURCE_NAME, obj);
    }
  },

  async fetch_source_number({ commit }, obj) {
    if (obj) {
      commit(SOURCE_NUMBER, obj);
    }
  },

  async statsStructure({ commit }, obj) {
    var answ = await rest2Api.postBackEndNoFunc(
      'statistik/access/structure',
      obj
    );
    if (answ && answ.status == '1') commit(STATS_STRUCTURE, answ);
    return answ;
  },
  async statsUsers({ commit }, obj) {
    var answ = await rest2Api.postBackEndNoFunc(
      'statistik/access/structure',
      obj
    );
    if (answ && answ.status == '1') commit(STATS_BROKERS, answ);
    return answ;
  },
  async clearUsers({ commit }) {
    commit(STATS_BROKERS, null);
  },

  async statsData({ commit }, obj) {
    //om uppföljning
    if (obj.TypeOfStatistics == 'BudgetReport') {
      obj.TypeOfStatistics = 'Meetings';
      var meetings = await rest2Api.postBackEndNoFunc(
        'statistik/TypeOfStatistics',
        obj
      );

      if (meetings?.status == '1') {
        commit(STATS_FOLLOW_UP, { Meetings: meetings });
      } else commit(STATS_FOLLOW_UP, { Meetings: {} });

      obj.TypeOfStatistics = 'SalesCommission';

      var commission = await rest2Api.postBackEndNoFunc(
        'statistik/TypeOfStatistics',
        obj
      );

      if (commission?.status == '1') {
        commit(STATS_FOLLOW_UP, { SalesCommission: commission });
      } else commit(STATS_FOLLOW_UP, { SalesCommission: {} });

      obj.TypeOfStatistics = 'CompanyNps';

      var compNps = await rest2Api.postBackEndNoFunc(
        'statistik/TypeOfStatistics',
        obj
      );

      if (compNps?.status == '1') {
        commit(STATS_FOLLOW_UP, { CompanyNPS: compNps });
      } else commit(STATS_FOLLOW_UP, { CompanyNPS: {} });

      obj.TypeOfStatistics = 'BrokerNps';

      var brokerNps = await rest2Api.postBackEndNoFunc(
        'statistik/TypeOfStatistics',
        obj
      );

      if (brokerNps?.status == '1') {
        commit(STATS_FOLLOW_UP, { BrokerNPS: brokerNps });
      } else commit(STATS_FOLLOW_UP, { BrokerNPS: {} });

      obj.TypeOfStatistics = 'CompanyNps';
      obj.ContactRole = 'Seller';
      var sellerNps = await rest2Api.postBackEndNoFunc(
        'statistik/TypeOfStatistics',
        obj
      );

      if (sellerNps?.status == '1') {
        commit(STATS_FOLLOW_UP, { SellerNPS: sellerNps });
      } else commit(STATS_FOLLOW_UP, { SellerNPS: {} });

      obj.TypeOfStatistics = 'CompanyNps';
      obj.ContactRole = 'Buyer';

      var buyerNps = await rest2Api.postBackEndNoFunc(
        'statistik/TypeOfStatistics',
        obj
      );

      if (buyerNps?.status == '1') {
        commit(STATS_FOLLOW_UP, { BuyerNPS: buyerNps });
      }

      return {
        meetings: meetings,
        commission: commission,
        compnps: compNps,
        sellernps: sellerNps,
        buyernps: buyerNps,
        brokernps: brokerNps,
      };
      //om pdf
    } else if (obj.TypeOfStatistics == 'Pdf') {
      obj.TypeOfStatistics = 'CompanyNps';
      var compNpsPdf = await rest2Api.postBackEndNoFunc(
        'statistik/TypeOfStatistics',
        obj
      );
      if (compNpsPdf?.status == '1') {
        commit(PDF_DATA, { CompanyNPS: compNpsPdf });
      } else commit(PDF_DATA, { CompanyNPS: {} });

      obj.TypeOfStatistics = 'Quotes';

      var quotesPdf = await rest2Api.postBackEndNoFunc(
        'statistik/TypeOfStatistics',
        obj
      );
      if (quotesPdf?.status == '1') {
        commit(PDF_DATA, { Comments: quotesPdf });
      } else commit(PDF_DATA, { Comments: {} });

      obj.TypeOfStatistics = 'BrokerNps';
      obj.WithItems = true;
      var brokerNpsPdf = await rest2Api.postBackEndNoFunc(
        'statistik/TypeOfStatistics',
        obj
      );

      if (brokerNpsPdf?.status == '1') {
        commit(PDF_DATA, { BrokerNPS: brokerNpsPdf });
      } else commit(PDF_DATA, { BrokerNPS: {} });

      //övrig stats/nps
    } else {
      // om betyg så måste withitems vara med även om mäklaren inte beställt det
      if (obj.TypeOfStatistics == 'BrokerNpsCustom') {
        obj.TypeOfStatistics = 'BrokerNps';
        obj.WithItems = true;
      }
      var answ = await rest2Api.postBackEndNoFunc(
        'statistik/TypeOfStatistics',
        obj
      );
      if (answ?.status == '1') {
        commit(STATS_DATA, answ);
      } else commit(STATS_DATA, {});
      return { standard: answ };
    }
  },
};
