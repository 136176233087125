<template>
  <div>
    <v-container>
      <Description
        :url="headerImage"
        :header="header"
        :description="description"
      />
      <v-row>
        <v-card flat class="cardStyle">
          <div>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <v-row>
                <v-radio-group v-model="selectedTypeOfStats" row>
                  <v-radio
                    :label="companyNpsLabel"
                    value="CompanyNps"
                  ></v-radio>
                  <v-radio
                    v-if="!this.customNps"
                    label="Mäklaren"
                    value="BrokerNps"
                  ></v-radio>
                  <v-radio
                    v-if="this.customNps"
                    label="Betyg"
                    value="BrokerNpsCustom"
                  ></v-radio>
                  <v-radio label="Kommentarer" value="Quotes"></v-radio>
                  <v-radio
                    v-if="this.org == 'SMH'"
                    label="Skapa pdf"
                    value="Pdf"
                  ></v-radio>
                </v-radio-group>
              </v-row>
              <!-- Kund, för oss -->

              <v-row v-if="showCustomerSelector">
                <v-select
                  dense
                  label="Välj kund"
                  outlined
                  :items="customers"
                  item-text="Name"
                  item-value="Org"
                  return-object
                  v-model="selectedCustomer"
                ></v-select>
              </v-row>
              <!-- kontor -->
              <v-row>
                <v-select
                  dense
                  label="Välj kontor"
                  outlined
                  :items="offices(selectedCustomer?.Org)"
                  item-text="Name"
                  item-value="Id"
                  return-object
                  v-model="selectedOffice"
                ></v-select>
              </v-row>
              <!-- Mäklare-->
              <v-row>
                <v-select
                  dense
                  label="Välj mäklare"
                  outlined
                  :items="users"
                  item-text="Name"
                  item-value="Id"
                  return-object
                  v-model="selectedUser"
                ></v-select>
              </v-row>
            </v-col>
            <!--datum -->
            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-menu
                  ref="menuFrom"
                  v-model="menuFrom"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="test"
                      dense
                      v-model="dateFrom"
                      outlined
                      label="Fr.o.m"
                      append-icon="arrow_drop_down"
                      v-on="on"
                      @click:append="on.click"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFrom"
                    @input="menuFrom = false"
                    no-title
                    scrollable
                    :first-day-of-week="1"
                    locale="sv-se"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-menu
                  ref="menuTo"
                  v-model="menuTo"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      v-model="dateTo"
                      outlined
                      label="T.o.m"
                      append-icon="arrow_drop_down"
                      @click:append="on.click"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateTo"
                    @input="menuTo = false"
                    no-title
                    scrollable
                    :first-day-of-week="1"
                    locale="sv-se"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <!--slut datum-->
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <v-row>
                <!--Visa items (alla svar)-->
                <v-switch
                  style="margin-right: 30px"
                  v-if="
                    this.selectedTypeOfStats == 'BrokerNps' ||
                    this.selectedTypeOfStats == 'CompanyNps' ||
                    this.selectedTypeOfStats == 'BrokerNpsCustom'
                  "
                  v-model="showItems"
                  label="Visa lista med alla svar"
                ></v-switch>

                <v-switch
                  style="margin-right: 30px"
                  v-if="
                    this.selectedTypeOfStats == 'BrokerNps' ||
                    (this.selectedTypeOfStats == 'CompanyNps' &&
                      (this.users?.length > 1 ||
                        this.offices(this.selectedCustomer?.Org)[0]?.Id ==
                          '000000'))
                  "
                  v-model="showTop5"
                  label="Visa toppmäklare"
                ></v-switch>

                <!-- <v-switch
                v-if="this.selectedTypeOfStats != 'Quotes'"
                v-model="showTop5Office"
                label="Visa toppmäklare per kontor"
              ></v-switch>-->
                <!--visa bästa kommentaren-->
                <v-switch
                  v-if="this.selectedTypeOfStats == 'Quotes'"
                  v-model="showBestComments"
                  label="Visa de bästa kommentarerna (Tänk på att justera datumet om du vill välja bland alla kommentarer)"
                ></v-switch>
              </v-row>
            </v-col>
          </div>
        </v-card>
      </v-row>
      <v-card flat class="loaderStyle" v-if="loadingPage == true">
        <v-label
          >De bästa kommentarerna väljs ut med hjälp av AI, det kan ta en
          stund..</v-label
        >
        <v-skeleton-loader type="table"></v-skeleton-loader>
      </v-card>
      <v-row width="100%" v-if="loadingPage == false">
        <NpsCharts
          v-if="
            (this.selectedTypeOfStats == 'BrokerNps' ||
              this.selectedTypeOfStats == 'CompanyNps') &&
            this.showTop5 == false
          "
          :maxScale="scale"
          :statsType="this.selectedTypeOfStats"
        />
        <GradeCharts v-if="this.selectedTypeOfStats == 'BrokerNpsCustom'" />
        <NpsItems v-if="this.showItems" />
        <NpsComments v-if="this.selectedTypeOfStats == 'Quotes'" />
        <NpsUsers v-if="this.showTop5" />
        <CreatePdf
          v-if="
            this.selectedTypeOfStats == 'Pdf' &&
            this.selectedUser &&
            this.selectedUser?.Id != '000000'
          "
          :broker="this.selectedUser"
        />
        <span
          style="padding: 20px"
          v-if="
            this.selectedTypeOfStats == 'Pdf' &&
            (!this.selectedUser || this.selectedUser.Id == '000000')
          "
        >
          Välj mäklare (du måste välja ett kontor innan du kan välja mäklare)
        </span>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import mixin from '@/mixin.js';
import NpsCharts from '@/components/stats/nps_graphs.vue';
import NpsItems from '@/components/stats/nps_items.vue';
import NpsComments from '@/components/stats/nps_comments.vue';
import NpsUsers from '@/components/stats/nps_users.vue';
import Description from '@/components/description.vue';
import CreatePdf from '@/components/stats/createPdf.vue';
import GradeCharts from '@/components/stats/grade_chart.vue';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';

export default {
  name: 'NPS',
  mixins: [mixin],

  data() {
    return {
      loadingPage: false,
      dateFrom: this.getDateFrom(),
      menuFrom: false,
      dateTo: this.getDateTo(),
      menuTo: false,

      header: 'NPS',
      description: 'Se de betyg som kunderna lämnat om er och er mäklartjänst.',
      headerImage:
        'https://statistik.boostad.net/img/BOOSTAD/IMAGE/portal_graphpen.jpg',

      selectedCustomer: null,
      showCustomerSelector: false,
      selectedOffice: null,
      selectedUser: null,
      selectedTypeOfStats: 'CompanyNps',
      //  estateTypes: ['Alla', 'Bostadsrätt'],
      //  selectedEstateType: 'Alla',
      // part: ['Alla', 'Visningsdeltagare', 'Säljare', 'Köpare'],
      //  selectedPart: 'Alla',
      showItems: false,
      showTop5: false,
      // showTop5Office: false,
      showBestComments: false,
      scale: '10',
    };
  },
  watch: {
    selectedCustomer() {
      this.$store.dispatch('infopingstore/clearUsers');
      //välj alla kontor eller det enda som finns
      this.selectedOffice = this.offices(this.selectedCustomer?.Org)[0];
    },
    customers() {
      //om bara en kund
      if (this.customers?.length == 1) {
        this.selectedCustomer = this.customers[0];
      } else this.showCustomerSelector = true;
    },

    selectedOffice() {
      if (this.selectedOffice?.Id != '000000') {
        this.getUsers();
      } else if (this.selectedOffice?.Id == '000000') {
        this.$store.dispatch('infopingstore/clearUsers');
        this.selectedUser = null;
        this.getStats();
      }
      if (this.users?.length > 0) this.selectedUser = this.users[0];
    },
    selectedUser() {
      if (this.selectedUser != null) this.getStats();
    },

    dateFrom(val) {
      if (val) {
        this.getStats();
        //   this.loadingPage = true;
      }
    },
    dateTo(val) {
      if (val) {
        this.getStats();
        // this.loadingPage = true;
      }
    },
    selectedTypeOfStats() {
      if (this.selectedTypeOfStats == 'BrokerNpsCustom') this.scale = '5';
      else this.scale = '10';
      if (this.selectedTypeOfStats == 'Quotes') this.showItems = false;
      this.getStats();
    },
    showItems(val) {
      if (val == true) {
        this.showTop5 = false;
        this.getStats();
      }
    },
    showTop5(val) {
      if (val == true) {
        this.showItems = false;
        this.getStats();
      } else if (val == false && this.showItems == false) this.getStats();
    },
    // showTop5Office() {
    //   this.getStatsOffice();
    // },
    showBestComments(val) {
      if (val == true) this.loadingPage = true;
      this.getStats();
    },
  },
  async beforeMount() {},
  async mounted() {
    await this.getStructure();

    //  if (this.offices(this.selectedCustomer?.Org)?.length > 1) {
    //om flera kontor hämta stats, annars hämtas det av user-watchen
    //   await this.getStats();
    // }
  },
  components: {
    Description,
    NpsCharts,
    NpsItems,
    NpsComments,
    NpsUsers,
    CreatePdf,
    GradeCharts,
  },
  computed: {
    ...mapGetters({
      customers: 'infopingstore/statsCustomers',
      offices: 'infopingstore/statsOffices',
      // users: 'infopingstore/statsUsers',
    }),
    companyNpsLabel() {
      if (this.customNps) return 'NPS';
      else return 'Företaget';
    },
    customNps() {
      if (this.org == 'SMH' || this.org == 'EO') return true;
      else return false;
    },
    users() {
      var users = get(
        this.$store.state.infopingstore,
        'statsBrokers.Companies[0].Customers[0].Users',
        null
      );
      var userslist = cloneDeep(users);
      //sortera
      if (userslist?.length > 0)
        userslist.sort((a, b) =>
          a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
        );
      // lägg till "Alla mäklare" om det är fler än en
      if (userslist?.length > 1)
        userslist.unshift({ Name: 'Alla mäklare', Id: '000000' });
      return userslist;
    },

    session() {
      return get(this.$store.state.azurestore.userInfo, 'session', null); //johan '55E4AA6D-85DB-43AB-9AEE-D6475A10527A'; //
    },
  },
  methods: {
    async getStructure() {
      var obj = { Session: this.session };

      var res = await this.$store.dispatch('infopingstore/statsStructure', obj);

      if (res?.status == 0) {
        this.alertDialogNoCancel('Inget svar', 'Felkod: ' + res.error, () =>
          console.log('user ok')
        );
      }
    },
    async getUsers() {
      var obj = new Object();
      obj.Session = this.session;
      obj.Org = this.selectedCustomer.Org;
      obj.CustomerId = this.selectedOffice.Id;

      var res = await this.$store.dispatch('infopingstore/statsUsers', obj);
      if (res?.status == 1) this.selectedUser = this.users[0];
      else
        this.alertDialogNoCancel(
          'Hittar inga mäklare',
          'Felkod: ' + res.error,
          () => console.log('user ok')
        );
    },
    async getStats() {
      await this.$store.dispatch(
        'infopingstore/statsData',
        await this.statsObj()
      );
      this.loadingPage = false;
    },
    async statsObj() {
      var obj = new Object();
      //tömma variablerna? eller inte ha dem globala

      obj.fromDate = this.dateFrom;
      obj.lessDate = this.getDateLess();
      obj.Last = null;
      obj.Session = this.session;
      obj.BrokerSystem = null;
      if (this.selectedOffice?.Id != '000000')
        obj.CustomerId = this.selectedOffice?.Id;
      //om inget customerId skicka inte med org
      if (obj.CustomerId) obj.Org = this.selectedCustomer.Org;
      obj.EstateId = null;
      obj.SubType = null;
      obj.StreetAddress = null;
      if (this.selectedUser?.Id != '000000')
        obj.BrokerId = this.selectedUser?.Id;
      obj.BrokerUid = null;
      obj.ContactId = null;
      obj.ContactRole = null;
      obj.ContactName = null;
      obj.What = null;
      obj.Type = null;
      obj.Value = null;
      obj.Extra = null;
      obj.Rating = null;
      obj.Scale = null;
      obj.Nps = null;
      obj.Ip4 = null;
      obj.SourceId = null;
      obj.SourceName = null;
      obj.SourceNumber = null;
      obj.PhoneNumber = null;
      //SMH ville ha topp 20
      if (this.showTop5 || this.showBestComments) obj.Top = 20;
      obj.TypeOfStatistics = this.selectedTypeOfStats;
      obj.WithItems = this.showItems;
      obj = await this.clean_null_obj(obj);
      return obj;
    },

    async clean_null_obj(obj) {
      return JSON.parse(
        JSON.stringify(obj, (key, value) => {
          if (value !== null) return value;
        })
      );
    },
    getDateTo() {
      var d = new Date();

      d = d.toISOString().slice(0, 10);

      return d;
    },

    getDateFrom() {
      var d = new Date();
      d.setDate(d.getDate() - 30);
      d = d.toISOString().slice(0, 10);

      return d;
    },
    getDateLess() {
      var addDays = function (str, days) {
        var myDate = new Date(str);
        myDate.setDate(myDate.getDate() + parseInt(days));
        return myDate;
      };

      var myDate = addDays(this.dateTo, 1);
      myDate = myDate.toISOString().slice(0, 10);
      return myDate;
    },
    checkOption(val) {
      console.log('ddd', val);
    },
  },
};
</script>
<style scoped>
.loaderStyle {
  margin-top: 20px;
  padding: 20px;
}
.v-menu__content {
  background-color: var(--calendar-background);
}
</style>
